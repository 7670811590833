import _isString from "is-string";
import _isNumberObject from "is-number-object";
import _isBooleanObject from "is-boolean-object";
import _isSymbol from "is-symbol";
import _isBigint from "is-bigint";
var exports = {};
var isString = _isString;
var isNumber = _isNumberObject;
var isBoolean = _isBooleanObject;
var isSymbol = _isSymbol;
var isBigInt = _isBigint; // eslint-disable-next-line consistent-return

exports = function whichBoxedPrimitive(value) {
  // eslint-disable-next-line eqeqeq
  if (value == null || typeof value !== "object" && typeof value !== "function") {
    return null;
  }

  if (isString(value)) {
    return "String";
  }

  if (isNumber(value)) {
    return "Number";
  }

  if (isBoolean(value)) {
    return "Boolean";
  }

  if (isSymbol(value)) {
    return "Symbol";
  }

  if (isBigInt(value)) {
    return "BigInt";
  }
};

export default exports;